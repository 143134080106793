import React, { useContext } from 'react';
import { Box, ResponsiveContext } from 'grommet';
import styled, { css } from 'styled-components';

const StyledBox = styled(Box)`
    position: relative;
    &:after {
        content: '';
        display: block;
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        width: 1rem;
        height: 1rem;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }

    .gatsby-image-wrapper {
        min-width: 290px;
        height: auto;
        flex-shrink: 0;
    }
`;

const TagBox = ({ children, ...props }) => {
    const size = useContext(ResponsiveContext);
    return (
        <StyledBox
            background="light-2"
            round="small"
            pad={{ top: 'medium', bottom: 'large', left: 'medium', right: 'medium' }}
            {...props}
        >
            {children}
        </StyledBox>
    );
};

export default TagBox;

import styled, { css } from 'styled-components';
import { media } from './styles';

export const Tag = styled.span`
    display: block;
    background-color: ${(props) => props.theme.global.colors.brand.light};
    position: ${(props) => (props.relative ? 'relative' : 'absolute')};
    color: #fff;
    padding: 10px 10px 17px;
    border-radius: 4px;
    top: -40px;
    width: 100%;
    max-width: 300px;
    transform: rotate(-5deg);
    z-index: 9;
    text-align: center;
    font-size: 1.25rem;
    ${(props) =>
        props.position === 'right'
            ? css`
                  right: 10px;
                  transform: rotate(5deg);
              `
            : css`
                  left: 10px;
                  transform: rotate(-5deg);
              `};

    span {
        font-size: 150%;
    }

    a {
        color: #fff;
        margin-top: 8px;
        &:hover {
            text-decoration: underline;
        }
    }

    ${media.md`
  padding: 10px 10px 1.5rem 10px;

`}

    ${media.lg`
    top: 0px;
  border-radius: 6px;

  transform: rotate(-7deg);
  ${(props) =>
      props.position === 'right'
          ? css`
                right: 20px;
                transform: rotate(7deg);
            `
          : css`
                left: 20px;
                transform: rotate(-7deg);
            `};

`}


&:after {
        content: '';
        display: block;
        border-radius: 50%;
        background-color: #fff;

        ${(props) =>
            props.greyDot &&
            css`
                background-color: #f2f2f2;
            `}

        width: 1rem;
        height: 1rem;
        position: absolute;
        bottom: 0.75rem;
        right: 0.75rem;
    }
`;
